<template>
  <b-card>
    <b-list-group>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <div>
          <span class="mr-1">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
          </span>
          <b>
            {{ $t('courses.date_of_view') }}
          </b>
        </div>
        <div
          class="d-flex justify-content-end text-right align-items-center w-50"
        >
          <b>{{ $_dateTimeFormatter(info.created_date) }}</b>
        </div>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <div>
          <span class="mr-1">
            <feather-icon
              icon="ClockIcon"
              size="16"
            />
          </span>
          <b>
            {{ $t('courses.time_spent') }}
          </b>
        </div>
        <div
          class="d-flex justify-content-end text-right align-items-center w-50"
        >
          <b>{{ info.overall_time }}</b>
        </div>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <div>
          <span class="mr-1">
            <feather-icon
              icon="AwardIcon"
              size="16"
            />
          </span>
          <b>
            {{ $t('courses.result') }}
          </b>
        </div>
        <div
          class="d-flex justify-content-end text-right align-items-center w-50"
        >
          <b>{{ info.total_score }}</b>
        </div>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <div>
          <span class="mr-1">
            <feather-icon
              icon="BookmarkIcon"
              size="16"
            />
          </span>
          <b>
            {{ $t('courses.max_score') }}
          </b>
        </div>
        <div
          class="d-flex justify-content-end text-right align-items-center w-50"
        >
          <b>{{ info.max_score }}</b>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'
import formatters from '@/mixins/extra'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
  },
  mixins: [formatters],
  props: {
    info: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    getTimeSpent() {
      const started_time = new Date(this.info.created_date).getTime()
      const ended_time = new Date(this.info.end_time).getTime()
      return (ended_time - started_time) / 60000
    },
  },
}
</script>

<style>

</style>
