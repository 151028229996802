<template>
  <div>
    <app-timeline-item
      :title="message.author.full_name"
      :subtitle="message.message"
      :icon="message.photo"
      :time="new Date(message.created_date).toLocaleString()"
      variant="success"
    >
      <div v-if="message.file">
        <audio
          v-if="message.file.includes('mp3')"
          :src="message.file"
          controls
        />
        <video
          v-if="message.file.includes('mp4')"
          :src="message.file"
          controls
        />
        <b-img
          v-if="message.file.includes('png')"
          :src="message.file"
          controls
        />
      </div>
      <b-button
        variant="link"
        class="text-secondary timeline-item-time text-nowrap font-small mt-1 pl-0 pr-50"
        @click="$emit('reply', message.message, message.id, message.author.full_name)"
      >
        {{ $t('general.answer') }}
      </b-button>

      <b-button
        variant="link"
        class="text-secondary timeline-item-time text-nowrap font-small mt-1 pl-25"
        @click="$emit('delete', message.id)"
      >
        {{ $t('general.delete') }}
      </b-button>
      <template v-if="message.children.length">
        <message
          v-for="item in message.children"
          :key="item.id"
          :message="item"
          class="mt-2"
          @delete="(id) => $emit('delete', id)"
          @reply="(message, id, name) => $emit('reply', message, id, name)"
        />
      </template>
    </app-timeline-item>
  </div>
</template>

<script>

import { BButton, BImg } from 'bootstrap-vue'
import AppTimelineItem from './AppTimelineItem.vue'

export default {
  name: 'Message',
  components: {
    AppTimelineItem,
    BButton,
    BImg,
  },
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style>

</style>
