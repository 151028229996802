<template>
  <div>
    <vue-perfect-scrollbar
      class="comment-scroll-area"
      style="height: 300px"
      @scroll.prevent
      @ps-y-reach-start="lazyLoad"
    >
      <app-timeline class="px-2 pt-2">
        <message
          v-for="item in GET_COMMENTS.results"
          :key="item.id"
          class="mt-1"
          :message="item"
          @reply="replyToComment"
          @delete="deleteComment"
        />
      </app-timeline>
    </vue-perfect-scrollbar>

    <b-form-group class="pt-2">
      <label
        for="textarea-default"
        class="w-100"
      >
        <div
          v-if="reply"
          class="d-flex align-items-center justify-content-between w-100"
        >
          <div class="d-flex align-items-center ">
            <feather-icon
              icon="CornerUpLeftIcon"
              size="25"
              class="mr-2"
              @click="CancelReply"
            />
            <div>
              <h5 class="mb-0 pb-0">{{ reply_user }}</h5>
              <span>{{ reply_to_message }}</span>
            </div>
          </div>

          <feather-icon
            icon="XIcon"
            class="text-danger mb-50"
            size="20"
            style="cursor: pointer"
            @click="CancelReply"
          />
        </div>
        <span v-if="!reply">{{ $t('tests.text_message') }}</span>
      </label>
      <b-form-textarea
        v-model="message"
        name="textarea"
        rows="3"
        :placeholder="$t('tests.text_message')"
      />
    </b-form-group>

    <video
      v-if="streaming"
      ref="videoStream"
      width="640"
      height="480"
      autoplay
    />
    <canvas
      v-show="isPhotoTaken"
      id="photoTaken"
      ref="canvas"
      :width="640"
      :height="480"
    />

    <div class=" d-flex align-items-center justify-content-between">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="md"
        :disabled="!message.length && !photoFile"
        @click="sendMessage"
      >
        {{ $t('general.send') }}
      </b-button>
      <div class="d-flex align-items-center">
        <b-button
          class="snap_btn mr-1"
          variant="warning"
          @click="takePhoto"
        >
          <feather-icon
            icon="CameraIcon"
            size="22"
          />
        </b-button>
        <VueRecordAudio
          mode="press"
          class="mr-1 btn btn-primary rounded-pill btn-sm"
          @result="onResultAudio"
        />
        <vue-record-video
          mode="press"
          @stream="onStream"
          @result="onResultVideo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BFormTextarea,
  VBToggle,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { VueRecordAudio, VueRecordVideo } from '@codekraft-studio/vue-record'
import { mapActions, mapGetters } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import Message from './CommentsPart/Message.vue'
import useJwt from '@/auth/jwt/useJwt'
import configs from '../../axios.config'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormTextarea,
    VuePerfectScrollbar,
    Message,
    AppTimeline,
    VueRecordAudio,
    VueRecordVideo,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    lessonHistory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      backendIP: '',
      message: '',
      reply_to_message: '',
      reply_user: '',
      reply: false,
      parent_message_id: null,
      showCamera: false,
      params: {
        object_id: this.$route.params.id,
        page_size: 1000,
      },
      messages: [
        {
          title: 'Сергей Королев',
          message: 'Привет, как дела?',
          icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/800px-User_icon_2.svg.png',
          time: 'Сегодня в 12:00',
          child: [
            {
              title: 'Сергей Королев',
              message: 'Привет, как дела?',
              icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/800px-User_icon_2.svg.png',
              time: 'Сегодня в 12:00',
              child: [],
            },
          ],
        },
        {
          title: 'Сергей Королев',
          message: 'Привет, как дела?',
          icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/800px-User_icon_2.svg.png',
          time: 'Сегодня в 12:00',
          child: [
            {
              title: 'Сергей Королев',
              message: 'Привет, как дела?',
              icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/800px-User_icon_2.svg.png',
              time: 'Сегодня в 12:00',
              child: [],
            },
          ],
        },
      ],
      audioFile: '',
      videoFile: '',
      streaming: false,
      isPhotoTaken: false,
      photoFile: null,
    }
  },
  computed: {
    ...mapGetters('files', ['GET_COMMENTS']),
  },
  async mounted() {
    this.connect()
    console.log('sdfs')
    const { id } = useJwt.getUserData()
    await this.FETCH_COMMENTS(this.params)
    await this.FETCH_NOTIFICATIONS({ contact_id: id, page_size: 1000 })
    this.FETCH_NOTIFICATIONS({ contact_id: id, page_size: 1000 })
    this.scrollToEnd()
  },
  beforeDestroy() {
    this.$socket.close()
  },
  methods: {
    ...mapActions('files', ['FETCH_COMMENTS', 'CREATE_COMMENT', 'DELETE_COMMENT']),
    ...mapActions('courses', ['FETCH_NOTIFICATIONS']),
    replyToComment(message, id, user) {
      this.reply_to_message = message
      this.reply = true
      this.parent_message_id = id
      this.reply_user = user
    },
    lazyLoad(e) {
      console.log(e)
      this.params.page = this.GET_COMMENTS.next
      // this.FETCH_COMMENTS(this.params)
    },
    scrollToEnd() {
      setTimeout(() => {
        const container = this.$el.querySelector('.ps-container')
        container.scrollTop = container.scrollHeight
      }, 0)
    },
    CancelReply() {
      this.reply_to_message = ''
      this.reply_to_message = ''
      this.reply_user = ''
      this.reply = false
      this.parent_message_id = null
    },
    sendMessage() {
      const data = {
        message: this.message,
        object_id: this.$route.params.id,
        content_type: 42,
      }
      if (this.reply) {
        data.parent = this.parent_message_id
      }
      if (this.photoFile) {
        data.file = this.photoFile
      }
      const fd = this.$_objectToFormData(data)
      this.CREATE_COMMENT(fd)
        .then(res => {
          this.sendMessageWithSocket(this.message, res.data.id)
          this.message = ''
          this.CancelReply()
          this.FETCH_COMMENTS(this.params)
            .then(() => {
              this.scrollToEnd()
            })
          this.$_successToast(this.$t('general.success'))
          this.photoFile = null
          this.streaming = false
          this.isPhotoTaken = false
        })
        .catch(() => {
          this.$_errorToast(this.$t('general.error'))
        })
    },
    async connect() {
      await this.$connect(`${configs.notificationSocket}/?token=${useJwt.getToken()}`, {
        reconnection: false,
        reconnectionAttempts: 5,
        reconnectionDelay: 3000,
        format: 'json',
      })
    },
    async sendMessageWithSocket(comment, id) {
      const message = {
        action: 'send_notify',
        pk: this.lessonHistory.teachers,
        request_id: new Date().getTime(),
        content_type: this.lessonHistory.content_type_comment,
        student: this.lessonHistory.student,
        comment,
        message_id: id,
        object_id: this.$route.params.id,
      }
      this.$socket.sendObj(message)
    },
    deleteComment(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_COMMENT(id)
              .then(() => {
                this.FETCH_COMMENTS(this.params)
                this.$_successToast(this.$t('general.success'))
              })
              .catch(() => {
                this.$_errorToast(this.$t('general.error'))
              })
          }
        })
    },
    onResultAudio(blob) {
      this.audioFile = new File([blob], `audio-${new Date().toLocaleString()}.mp3`, { type: 'audio/mp3' })
      const fd = new FormData()
      fd.append('file', this.audioFile)
      fd.append('message', this.message)
      fd.append('object_id', this.$route.params.id)
      fd.append('content_type', 42)
      fd.append('is_active', true)
      this.CREATE_COMMENT(fd)
        .then(res => {
          this.sendMessageWithSocket(this.message, res.data.id)
          this.message = ''
          this.CancelReply()
          this.FETCH_COMMENTS(this.params)
            .then(() => {
              this.scrollToEnd()
            })
          this.$_successToast(this.$t('general.success'))
        })
        .catch(() => {
          this.$_errorToast(this.$t('general.error'))
        })
    },
    onResultVideo(blob) {
      this.videoFile = new File([blob], `video-${new Date().toLocaleString()}.mp4`, { type: 'video/mp4' })
      const fd = new FormData()
      fd.append('file', this.videoFile)
      fd.append('message', this.message)
      fd.append('object_id', this.$route.params.id)
      fd.append('content_type', 42)
      fd.append('is_active', true)
      this.CREATE_COMMENT(fd)
        .then(res => {
          this.sendMessageWithSocket(this.message, res.data.id)
          this.message = ''
          this.CancelReply()
          this.FETCH_COMMENTS(this.params)
            .then(() => {
              this.scrollToEnd()
            })
          this.$_successToast(this.$t('general.success'))
          this.streaming = false
        })
        .catch(() => {
          this.$_errorToast(this.$t('general.error'))
        })
    },
    onStream(stream) {
      this.streaming = true
      this.$nextTick(() => {
        this.$refs.videoStream.srcObject = stream
      })
    },
    takePhoto() {
      if (!this.showCamera) {
        this.streaming = true

        // eslint-disable-next-line no-multi-assign
        const constraints = (window.constraints = {
          audio: false,
          video: true,
        })
        console.log(navigator.userAgent)
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false
            this.$refs.videoStream.srcObject = stream
          })
          .catch(() => {
            this.streaming = true
          })
        this.showCamera = true
      } else {
        this.streaming = false
        const context = this.$refs.canvas.getContext('2d')
        context.drawImage(this.$refs.videoStream, 0, 0, 640, 480)
        this.$refs.canvas.toBlob(blob => {
          this.photoFile = new File([blob], `image-${new Date().toLocaleString()}.png`, { type: 'image/png' })
        })
        this.isPhotoTaken = true
      }
    },
  },
}
</script>

<style lang="scss">
.w-500 .b-sidebar {
  width: 500px !important;
}

.comments-form .text-area {
  position: absolute;
  bottom: 33px;
  left: 0;
  right: 0;
  width: 95%;
  margin: 0 auto;
}

#comments-sidebar-id .b-sidebar-header button {
  margin: 0;
}

#comments-sidebar-id___title__ {
  margin-left: 30px;
  font-size: 19px;
  font-weight: 500;
}
.snap_btn {
  width: 64px;
  height: 64px;
  background-color: #4db6ac;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
