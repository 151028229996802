var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.$t('courses.detailed_report')}},[_c('b-tabs',_vm._l((_vm.parts),function(part){return _c('b-tab',{key:part.id,attrs:{"title":part.title}},[_c('b-table',{staticClass:"table-report",attrs:{"items":part.questions.flat(),"fields":_vm.getFields},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(index)",fn:function(props){return [_vm._v(" "+_vm._s(props.index + 1)+" ")]}},{key:"cell(answer)",fn:function(ref){
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._s(value)}})]}},{key:"cell(correct_answer)",fn:function(ref){
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._s(value)}})]}},{key:"cell(is_correct)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{staticClass:"text-center",class:{ 'text-success' : item.is_correct, 'text-danger' : !item.is_correct },attrs:{"size":"24","icon":item.is_correct ? 'CheckIcon' : 'XIcon'}})]}}],null,true)})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }