<template>
  <div>
    <b-row>
      <b-col md="6">
        <test-lesson-report-card :info="GET_ONE_LESSON_HISTORY" />
        <test-lesson-detailed-report
          v-if="GET_ONE_LESSON_HISTORY.contents.options && GET_ONE_LESSON_HISTORY.contents.options.report !== 'score'"
          :parts="GET_ONE_LESSON_HISTORY.result"
          :with-correct-answers="GET_ONE_LESSON_HISTORY.contents.options.report === 'with_correct_answers'"
        />
      </b-col>
      <b-col md="6">
        <test-lesson-report-chart
          :key="chart_key"
          :score="getScoreForChart"
          :message="GET_ONE_LESSON_HISTORY.message || ''"
        />
        <b-card>
          <comments :lesson-history="GET_ONE_LESSON_HISTORY" />
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-button @click="$router.go(-1)">
          {{ $t('general.cancel') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import testLessonReportCard from './components/testLessonReportCard.vue'
import testLessonReportChart from './components/testLessonReportChart.vue'
import testLessonDetailedReport from './components/testLessonDetailedReport.vue'
import Comments from '@/components/Comments.vue'

export default {
  components: {
    BRow,
    BCol,
    testLessonReportCard,
    testLessonReportChart,
    testLessonDetailedReport,
    Comments,
    BCard,
    BButton,
  },
  data() {
    return {
      report: {
        max_score: 40,
        score: 30,
        created_at: '2022-04-26',
        results: [
          {
            title: 'Part 1',
            questions: [
              {
                answer: 'are',
                is_correct: true,
                correct_answer: 'are',
                score: 1,
              },
              {
                answer: 'is from',
                is_correct: false,
                correct_answer: 'are from',
                score: 0,
              },
            ],
          },
          {
            title: 'Part 2',
            questions: [
              {
                answer: 'are',
                is_correct: true,
                correct_answer: 'are',
                score: 1,
              },
              {
                answer: 'is from',
                is_correct: false,
                correct_answer: 'are from',
                score: 0,
              },
            ],
          },
        ],
      },
      chart_key: 1,
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_ONE_LESSON_HISTORY', 'GET_CHECK_RESULTS']),
    getScoreForChart() {
      return parseFloat(((this.GET_ONE_LESSON_HISTORY.total_score * 100) / this.GET_ONE_LESSON_HISTORY.max_score).toFixed(2)) || 0
    },
  },
  mounted() {
    this.FETCH_ONE_LESSON_HISTORY(this.$route.params.id).then(() => {
      this.chart_key += 1
    })
    // this.CHECK_TEST(this.$route.params.id)
  },
  methods: {
    ...mapActions('courses', ['FETCH_ONE_LESSON_HISTORY', 'CHECK_TEST']),
  },
}
</script>

<style>

</style>
