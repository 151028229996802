<template>
  <b-card :title="$t('courses.detailed_report')">
    <b-tabs>
      <b-tab
        v-for="part in parts"
        :key="part.id"
        :title="part.title"
      >
        <b-table
          :items="part.questions.flat()"
          :fields="getFields"
          class="table-report"
        >
          <template #head()="{ label }">
            {{ $t(label) }}
          </template>
          <template #cell(index)="props">
            {{ props.index + 1 }}
          </template>
          <template #cell(answer)="{value}">
            <span v-html="value" />
          </template>
          <template #cell(correct_answer)="{value}">
            <span v-html="value" />
          </template>
          <template #cell(is_correct)="{ item }">
            <feather-icon
              size="24"
              :icon="item.is_correct ? 'CheckIcon' : 'XIcon'"
              :class="{ 'text-success' : item.is_correct, 'text-danger' : !item.is_correct }"
              class="text-center"
            />
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard, BTabs, BTab, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BTable,
  },
  props: {
    parts: {
      type: Array,
      required: false,
      default: () => {},
    },
    withCorrectAnswers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'index',
          label: '№',
        },
        {
          key: 'answer',
          label: 'courses.your_answer',
        },
        {
          key: 'is_correct',
          label: 'courses.result',
        },
        {
          key: 'correct_answer',
          label: 'courses.correct_answer',
        },
        // {
        //   key: 'score',
        //   label: 'courses.score',
        // },
      ],
    }
  },
  computed: {
    getFields() {
      if (this.withCorrectAnswers) {
        return this.fields
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.fields.slice(0, 3)
    },
  },
}
</script>

<style>
.table-report p, b, strong, h1, h2, h3, h4, h5, h6 {
  padding: 0!important;
  margin: 0!important;
}
</style>
